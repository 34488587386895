import { useCurrentInstrument } from 'store/instruments/selectors';

import {
  instrumentBenchmarkDetailsSchemaUrl,
  instrumentConstituentAssetsSchemaUrl,
  instrumentFactsheetSchemaUrl,
  instrumentGeneralDetailsSchemaUrl,
  instrumentLegalDocumentsSchemaUrl,
  instrumentMarketDetailsSchemaUrl,
  instrumentPartnerSchemaUrl,
  instrumentPcfSchemaUrl,
} from 'components/Form/formSchemas';
import {
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  DOCUMENTS_FINAL_TERMS_STEP,
  DOCUMENTS_PCF_STEP,
  FACTSHEET_STEP,
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { PARTNERS_STEP } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.steps';

const stepSchemaUrl = {
  [GENERAL_DETAILS_STEP]: instrumentGeneralDetailsSchemaUrl,
  [MARKET_STEP]: instrumentMarketDetailsSchemaUrl,
  [BENCHMARK_STEP]: instrumentBenchmarkDetailsSchemaUrl,
  [CONSTITUENT_STEP]: instrumentConstituentAssetsSchemaUrl,
  [FACTSHEET_STEP]: instrumentFactsheetSchemaUrl,
  [DOCUMENTS_FINAL_TERMS_STEP]: instrumentLegalDocumentsSchemaUrl,
  [DOCUMENTS_PCF_STEP]: instrumentPcfSchemaUrl,
  [PARTNERS_STEP]: instrumentPartnerSchemaUrl,
};

function useFormSchemaUrl(step: keyof typeof stepSchemaUrl) {
  const instrument = useCurrentInstrument();

  return `${stepSchemaUrl[step]}/status=${instrument.instrument?.status}`;
}

export default useFormSchemaUrl;
