export enum CONTACTS_MANAGEMENT_TAB_LABEL {
  LISTS = 'Distribution Lists',
  CONTACTS = 'Contacts',
}

export enum CONTACTS_MANAGEMENT_TAB_VALUE {
  LISTS = 'lists',
  CONTACTS = 'contacts',
}

export const CONTACTS_MANAGEMENT_TABS = [
  [CONTACTS_MANAGEMENT_TAB_LABEL.LISTS, CONTACTS_MANAGEMENT_TAB_VALUE.LISTS],
  [CONTACTS_MANAGEMENT_TAB_LABEL.CONTACTS, CONTACTS_MANAGEMENT_TAB_VALUE.CONTACTS],
];

export enum CONTACTS_TAB_LABEL {
  YOUR = 'Your contacts',
  PARTNER = 'Partner Contacts',
  EXTERNAL = 'External Contacts',
}

export enum CONTACTS_TAB_VALUE {
  YOUR = 'your',
  PARTNER = 'partner',
  EXTERNAL = 'external',
}

export const getContactsTabs = (companyName?: string) => [
  [`${companyName} Contacts`, CONTACTS_TAB_VALUE.YOUR],
  [CONTACTS_TAB_LABEL.PARTNER, CONTACTS_TAB_VALUE.PARTNER],
  [CONTACTS_TAB_LABEL.EXTERNAL, CONTACTS_TAB_VALUE.EXTERNAL],
];
