import Table, { TableCell, TableRow } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types/orders';
import { ProductType } from 'utils/types';
import { TableBody, Typography } from '@mui/material';
import { formatToCurrency } from 'utils/formatting';
import TooltipContent from 'components/Tooltip/TooltipContent';
import BigNumber from 'bignumber.js';

interface ExpectedDeliverablesExpectedTableProps {
  productType: ProductType;
  order?: Order;
  deliverables?: Deliverable[];
}

const DeliverablesTable = ({
  productType,
  order,
  deliverables,
}: ExpectedDeliverablesExpectedTableProps) => {
  const isUSDCash = order?.deliveryType === 'CASH' && order?.deliveryCurrency === 'USD';

  const etpColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Reference Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`Price from ${deliverables && deliverables[0]?.priceAt} UTC`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    ...(isUSDCash
      ? []
      : [
          {
            label: 'Expected delivery',
            propName: 'expectedDelivery',
            propType: 'string',
            sort: false,
            tooltip: order?.deliveryType === 'CASH' && (
              <TooltipContent
                title={`USD/USDC: ${deliverables && deliverables[0]?.fxRate}`}
                description={`The FX rate between base currency and delivery currency is ${
                  deliverables && deliverables[0]?.fxRate
                }`}
              />
            ),
          },
        ]),
  ];

  const tokenColumns = [
    { label: 'Asset', propName: 'ticker', propType: 'string' },
    { label: 'Quantity', propName: 'quantity', propType: 'number' },
  ];

  const totals = (
    <TableRow key="totals" isExpanded>
      <TableCell maxWidth="24px" width="24px">
        TOTAL
      </TableCell>
      <TableCell>
        {deliverables
          ?.reduce(
            (totalWeight, deliverable) =>
              totalWeight.plus(new BigNumber(deliverable.weight || 0).multipliedBy(100)),
            new BigNumber(0)
          )
          .toFixed(2, BigNumber.ROUND_HALF_UP)}
        %
      </TableCell>
      <TableCell></TableCell>
      <TableCell key="priceT1Nav"></TableCell>
      <TableCell>
        {formatToCurrency(
          new BigNumber(order?.deliveries?.expectedTotalCash || 0)
            .decimalPlaces(2, BigNumber.ROUND_HALF_UP)
            .toNumber(),
          order?.deliveries?.expectedTotalCurrency
        )}
      </TableCell>
      {!isUSDCash && (
        <TableCell>
          {order?.deliveryType === 'CASH' &&
            formatToCurrency(
              order.deliveries?.expectedTotalInDeliveryCurrency,
              order?.deliveryCurrency
            )}
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort columns={etpColumns} />
          <TableBody>
            {deliverables?.length ? (
              deliverables
                ?.filter((deliverable) => deliverable.weight !== '0')
                .map((deliverable: Deliverable) => (
                  <TableRow key={deliverable.ticker}>
                    {/* Asset */}
                    <TableCell key="ticker">{deliverable?.ticker}</TableCell>
                    {/* Weight */}
                    <TableCell key="weight">
                      {deliverable?.weight
                        ? `${new BigNumber(deliverable?.weight)
                            .multipliedBy(100)
                            .toFixed(2, BigNumber.ROUND_HALF_UP)}%`
                        : '-'}
                    </TableCell>
                    {/* Quantity */}
                    <TableCell key="coinQty">{deliverable?.amount}</TableCell>
                    {/* Reference Price */}
                    <TableCell key="priceT1Nav">
                      {formatToCurrency(deliverable.price, deliverable?.currency)}
                    </TableCell>
                    {/* Market Value */}
                    <TableCell key="value">
                      {formatToCurrency(deliverable.totalInCurrency, deliverable?.currency)}
                    </TableCell>
                    {/* Expected Delivery */}
                    {!isUSDCash && (
                      <TableCell key="expectedTotalWithCash">
                        {order?.deliveryType === 'CASH'
                          ? formatToCurrency(
                              deliverable.totalInDeliveryCurrency,
                              order?.deliveryCurrency
                            )
                          : formatToCurrency(deliverable.amount, deliverable.ticker)}
                      </TableCell>
                    )}
                  </TableRow>
                ))
                .concat(totals)
            ) : (
              <TableRow>
                {/* Asset */}
                <TableCell key="ticker">
                  <Typography variant="labelMedium">Total</Typography>
                </TableCell>
                {/* Weight */}
                <TableCell key="weight">-</TableCell>
                {/* Quantity */}
                <TableCell key="coinQty">-</TableCell>
                {/* Reference Price */}
                <TableCell key="priceT1Nav">-</TableCell>
                {/* Market Value */}
                <TableCell key="value">-</TableCell>
                {/* Expected Delivery */}
                {!isUSDCash && <TableCell key="expectedTotalWithCash">-</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort
            columns={tokenColumns}
            columnAlignment={{ quantity: 'right' }}
          />
          <TableBody>
            {deliverables?.length ? (
              deliverables?.map((deliverable: Deliverable, index) => (
                <TableRow key={index}>
                  <TableCell key="ticker">{deliverable?.ticker}</TableCell>
                  <TableCell align="right" key="coinQty">
                    {deliverable.amount}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell key="ticker">-</TableCell>
                <TableCell align="right" key="cointQty">
                  -
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default DeliverablesTable;
