/* eslint-disable max-lines */
/* eslint-disable no-negated-condition */
/* eslint-disable complexity */
import { get } from 'lodash';
import { NAVReviewItem, TransactionLedger, TransactionType } from 'utils/types/nav';
import Table from 'components/Table';
import {
  NavReviewDifferencesColumnConfig,
  NavReviewDifferencesColumnConfigFA,
} from 'pages/Ledger/NAV/NAVDetailsPage/Cards/NAVDetailsCard/NAVDetailsTable/NAVDetailsTableColumnConfig';
import { Button } from 'components/Button/Button';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { Box, Stack, useTheme } from '@mui/material';
import { NAVDetailsTableRow } from 'pages/Ledger/NAV/NAVDetailsPage/Cards/NAVDetailsCard/NAVDetailsTable/NAVDetailsTableRow';
import {
  NAVCardRows,
  NavDetailsTransactions,
  OverrideFields,
  RebookDto,
} from 'pages/Ledger/NAV/NAVDetailsPage/utils/types';
import { filterTableRows } from 'pages/Ledger/NAV/NAVDetailsPage/utils/filterTableRows';
import { OverrideInput } from './OverrideInput/OverrideInput';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { ReactComponent as ArrowsExpand } from 'assets/arrows-expand.svg';
import { UserPermissions } from 'utils/types/permissions';
import Tooltip from 'components/Tooltip';
import { red } from 'theme/colors/baseColors';

export interface NAVDetailsTableProps {
  hasError: Boolean;
  hasOpenOrders?: Boolean;
  loading: Boolean;
  navReviewItem: NAVReviewItem;
  navTransactions: NavDetailsTransactions;
  onRebook: () => void;
  overrideBalance: RebookDto;
  onRebookEstimation: (val: RebookDto) => void;
  openTransactions: (val: {
    title: NAVCardRows;
    transactions: TransactionLedger[];
    NAVTransactionData: { onyxValue: string | number; FAValue: string | number };
  }) => void;
  userPermissions?: {
    [key in UserPermissions]: boolean;
  };
}

export const NAVDetailsTable = ({
  hasError,
  hasOpenOrders,
  loading,
  navReviewItem,
  navTransactions,
  onRebook,
  overrideBalance,
  onRebookEstimation,
  openTransactions,
  userPermissions,
}: NAVDetailsTableProps) => {
  const theme = useTheme();
  const updateNavLedgerBalance = (value: string | number, fieldName: OverrideFields) => {
    const regex = /^[+-.]$/;
    if (!regex.test(value as string)) {
      if (fieldName === 'overrideBalance') {
        onRebookEstimation({ [fieldName as string]: value });
      } else {
        const newOverrideBalance = structuredClone(overrideBalance);
        delete newOverrideBalance.overrideBalance;
        onRebookEstimation({ ...newOverrideBalance, [fieldName as string]: value });
      }
    }
  };

  const tableRows = filterTableRows(navReviewItem);
  const hasClosingBalanceDifference = Boolean(Number(navReviewItem.differences?.closingBalance));
  const hasNetOrdersDifference = Boolean(Number(navReviewItem.differences?.netOrders));
  const hasStakingDifference = Boolean(Number(navReviewItem.differences?.stakingRewards));

  // Static Rows

  const openingNavTableRow = !userPermissions?.isFundAccount ? (
    <TableRow data-qa-id={`${NAVCardRows.OPENING_BALANCE}-row`} key={NAVCardRows.OPENING_BALANCE}>
      <TableCell data-qa-id="nav-row-name">{NAVCardRows.OPENING_BALANCE}</TableCell>
      <TableCell data-qa-id="onyx-ledger-balance">{navReviewItem.totals?.openBalance}</TableCell>
      <TableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantTotals?.openBalance}
      </TableCell>
      <TableCell data-qa-id="onyx-difference">-</TableCell>
    </TableRow>
  ) : (
    <TableRow data-qa-id={`${NAVCardRows.OPENING_BALANCE}-row`} key={NAVCardRows.OPENING_BALANCE}>
      <TableCell data-qa-id="nav-row-name">{NAVCardRows.OPENING_BALANCE}</TableCell>
      <TableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantTotals?.openBalance}
      </TableCell>
    </TableRow>
  );

  // Dynamic Rows

  const netOrdersTooltip =
    navReviewItem.transactions?.find(
      (transaction) => transaction.type === TransactionType.REBOOK_NET_ORDERS_ADJUST
    )?.onyx?.reason ?? '';

  const netOrdersTableRowFA = (
    <TableRow data-qa-id={`${NAVCardRows.NET_ORDERS}-row`} key={NAVCardRows.NET_ORDERS}>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: 'inherit',
            alignItems: 'center',
          }}
        >
          {NAVCardRows.NET_ORDERS}{' '}
          <ArrowsExpand
            onClick={() => {
              openTransactions({
                title: NAVCardRows.NET_ORDERS,
                transactions:
                  navReviewItem.transactions?.filter(
                    (transaction) =>
                      transaction.type === TransactionType.ETP_CREATION ||
                      transaction.type === TransactionType.ETP_REDEMPTION ||
                      transaction.type === TransactionType.REBOOK_NET_ORDERS_ADJUST
                  ) ?? [],
                NAVTransactionData: {
                  onyxValue: navReviewItem?.totals?.netOrders ?? '-',
                  FAValue: get(navTransactions, NAVCardRows.NET_ORDERS).accountantValue ?? '-',
                },
              });
            }}
          />
        </Box>
      </TableCell>
      <OverrideInput
        data-qa-id={`closing-balance-override-cell`}
        value={navReviewItem?.totals?.netOrders ?? ''}
        onConfirm={(val) => {
          updateNavLedgerBalance(val as string, 'overrideNetOrders');
        }}
        editable={
          hasClosingBalanceDifference &&
          hasNetOrdersDifference &&
          navReviewItem.status === 'PENDING'
        }
        initialOverrideValue={get(navTransactions, NAVCardRows.NET_ORDERS).accountantValue ?? ''}
        tooltip={
          netOrdersTooltip && (
            <Tooltip
              title={<Box sx={{ padding: 1, color: 'white' }}>{netOrdersTooltip}</Box>}
              arrow={true}
              placement="top"
              bgColor="black"
            />
          )
        }
        hasDifference={hasNetOrdersDifference}
      />
      <TableCell
        data-qa-id="onyx-fund-accountant-balance"
        style={{ color: hasNetOrdersDifference ? red[70] : 'inherit' }}
      >
        {get(navTransactions, NAVCardRows.NET_ORDERS).accountantValue ?? '-'}
      </TableCell>
      <TableCell
        data-qa-id="onyx-difference"
        style={{ color: hasNetOrdersDifference ? red[70] : 'inherit' }}
      >
        {navReviewItem.differences?.netOrders ?? '-'}
      </TableCell>
    </TableRow>
  );

  const stakingRewardsTooltip =
    navReviewItem.transactions?.find(
      (transaction) => transaction.type === TransactionType.REBOOK_STAKING_REWARD_ADJUST
    )?.onyx?.reason ?? '';
  const stakingRewardsTableRow = (
    <TableRow data-qa-id={`${NAVCardRows.STAKING_REWARDS}-row`} key={NAVCardRows.STAKING_REWARDS}>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: 'inherit',
            alignItems: 'center',
          }}
        >
          {NAVCardRows.STAKING_REWARDS}{' '}
          <ArrowsExpand
            onClick={() => {
              openTransactions({
                title: NAVCardRows.STAKING_REWARDS,
                transactions:
                  navReviewItem.transactions?.filter(
                    (transaction) =>
                      transaction.type === TransactionType.EARN_REWARD ||
                      transaction.type === TransactionType.MEV_REWARD ||
                      transaction.type === TransactionType.REBOOK_STAKING_REWARD_ADJUST
                  ) ?? [],
                NAVTransactionData: {
                  onyxValue: navReviewItem?.totals?.stakingRewards ?? '-',
                  FAValue: get(navTransactions, NAVCardRows.STAKING_REWARDS).accountantValue ?? '-',
                },
              });
            }}
          />
        </Box>
      </TableCell>
      <OverrideInput
        data-qa-id={`closing-balance-override-cell`}
        value={navReviewItem?.totals?.stakingRewards ?? ''}
        onConfirm={(val) => {
          updateNavLedgerBalance(val as string, 'overrideStakingRewards');
        }}
        editable={
          hasClosingBalanceDifference && hasStakingDifference && navReviewItem.status === 'PENDING'
        }
        initialOverrideValue={
          get(navTransactions, NAVCardRows.STAKING_REWARDS).accountantValue ?? ''
        }
        tooltip={
          stakingRewardsTooltip && (
            <Tooltip
              title={<Box sx={{ padding: 1, color: 'white' }}>{stakingRewardsTooltip}</Box>}
              arrow={true}
              placement="top"
              bgColor="black"
            />
          )
        }
        hasDifference={hasStakingDifference}
      />
      <TableCell
        data-qa-id="onyx-fund-accountant-balance"
        style={{ color: hasStakingDifference ? red[70] : 'inherit' }}
      >
        {get(navTransactions, NAVCardRows.STAKING_REWARDS).accountantValue ?? '-'}
      </TableCell>
      <TableCell
        data-qa-id="onyx-difference"
        style={{ color: hasStakingDifference ? red[70] : 'inherit' }}
      >
        {navReviewItem.differences?.stakingRewards ?? '-'}
      </TableCell>
    </TableRow>
  );

  const hideStaking = !navReviewItem?.product?.constituentAssets?.find(
    (ca) => navReviewItem.ticker === ca.ticker && ca.isStaking === true
  );

  const closingBalanceTableRow = !userPermissions?.isFundAccount ? (
    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
      <TableCell>{NAVCardRows.CLOSING_BALANCE}</TableCell>
      <OverrideInput
        data-qa-id={`closing-balance-override-cell`}
        value={navReviewItem?.ledgerBalance ?? ''}
        onConfirm={(val) => {
          updateNavLedgerBalance(val as string, 'overrideBalance');
        }}
        editable={
          hasClosingBalanceDifference &&
          (hideStaking || !hasStakingDifference) &&
          !hasNetOrdersDifference &&
          navReviewItem.status === 'PENDING'
        }
        initialOverrideValue={navReviewItem.fundAccountantBalance ?? ''}
      />
      <TableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantBalance}
      </TableCell>
      <TableCell data-qa-id="onyx-difference">
        {navReviewItem.differences?.closingBalance}
      </TableCell>
    </TableRow>
  ) : (
    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
      <TableCell>{NAVCardRows.CLOSING_BALANCE}</TableCell>
      <TableCell data-qa-id="onyx-fund-accountant-balance">
        {navReviewItem.fundAccountantBalance}
      </TableCell>
    </TableRow>
  );

  const tableButtons = (
    <>
      {hasError && (
        <Button
          data-qa-id="rebook"
          disabled={loading || !navReviewItem._actions?.rebook || hasOpenOrders}
          type="button"
          variant="primary"
          onClick={() => {
            onRebook();
          }}
        >
          Rebook & Approve
        </Button>
      )}
    </>
  );

  return (
    <>
      <Table noPadding>
        <TableHeaderWithMultiSort
          columns={
            userPermissions?.isFundAccount
              ? NavReviewDifferencesColumnConfigFA
              : NavReviewDifferencesColumnConfig
          }
        />
        <TableBodyWithStates
          loadingData={loading}
          hasContent={tableRows.length > 0}
          noContentLabel="No data."
        >
          <>
            {openingNavTableRow}
            {tableRows.map((navReviewRow, index) => {
              if (navReviewRow === NAVCardRows.NET_ORDERS) return netOrdersTableRowFA;
              if (navReviewRow === NAVCardRows.STAKING_REWARDS) return stakingRewardsTableRow;

              return (
                <NAVDetailsTableRow
                  key={index}
                  rowKey={navReviewRow}
                  differenceItem={get(navTransactions, navReviewRow)}
                />
              );
            })}
            {closingBalanceTableRow}
          </>
        </TableBodyWithStates>
      </Table>
      <Stack mt={2} direction="row" justifyContent="end">
        <Stack spacing={2} justifyContent="end" direction="row" width="50%">
          {!userPermissions?.isFundAccount && tableButtons}
        </Stack>
      </Stack>
      {/* {rebookReasons && (
        <TextAreaComponent
          style={{ height: '100px' }}
          value={rebookReasons}
          label={'Reconciliation notes'}
          disabled                        // TODO: check how to present this
        />
      )} */}
    </>
  );
};
