/* eslint-disable max-lines-per-function */
import { DefaultValues } from 'react-hook-form';

// types, constants
import { OrderProfileProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { ORDER_PROFILE_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
// components
import Form from 'components/Form/Form';
import { Checkbox } from 'components/BooleanInputs';

// styles

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import { Card } from 'components/Card/Card';
import { renderDeliveryCutoff } from 'pages/Instruments/components/Form/EtpDetails/components/GeneralDetailsStep/utils/renderCutoff';

interface OrderProfileStepProps {
  onSubmit: () => void;
}

export const OrderProfileInKindStep = ({ onSubmit }: OrderProfileStepProps) => {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(ORDER_PROFILE_STEP) as OrderProfileProps;
  const saveValues = async (data: DefaultValues<OrderProfileProps>) => {
    saveInstrumentDraft({
      [ORDER_PROFILE_STEP]: data as OrderProfileProps,
    });
  };

  return (
    <Card
      body={
        <Form<OrderProfileProps>
          initialValues={formData}
          onBlur={saveValues}
          onSubmit={onSubmit}
          schemaUrl={'products/schema/data=order-profile-in-kind'}
        >
          <FormGridContainer>
            <Checkbox name="allowInKindOrder" />
          </FormGridContainer>
          <FormGridContainer>
            {renderDeliveryCutoff('IN-KIND', true, {
              timeName: 'inKindTPlusNCutOffTime',
            })}
            {renderDeliveryCutoff('IN-KIND', true, {
              timeName: 'inKindTPlusZeroCutOffTime',
            })}
          </FormGridContainer>
        </Form>
      }
    />
  );
};
