import { DefaultValues } from 'react-hook-form';

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// types, constants
import {
  BenchmarkDetailsProps,
  GeneralDetailsProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import {
  BENCHMARK_STEP,
  GENERAL_DETAILS_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';

// components
import Form from 'components/Form/Form';
import Footer from 'pages/Instruments/components/Footer';

// styles
import Input from 'components/Input';
import Select from 'components/Select';
import useCurrencyOptions from 'pages/Instruments/hooks/useCurrencyOptions';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import { RebalanceStrategy, RebalanceStrategyLabel } from 'utils/types/product';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import {
  StyledContentDiv,
  StyledRow,
  StyledTitleDiv,
  StyledToolTipRowsContainer,
} from 'shared/Tables/ContactsListsTable/ContactsList.styles';
import useFormSchemaUrl from 'pages/Instruments/components/Form/helpers/useFormSchemaUrl';

interface BenchmarkDetailsStepProps {
  goBack: () => void;
  onSubmit: () => void;
}

function BenchmarkDetailsStep({ goBack, onSubmit }: BenchmarkDetailsStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(BENCHMARK_STEP) as BenchmarkDetailsProps;
  const generalDetailsData = useInstrumentStepFormData(GENERAL_DETAILS_STEP) as GeneralDetailsProps;
  const instrumentBenchmarkDetailsSchemaUrl = useFormSchemaUrl(BENCHMARK_STEP);

  const isSingleAssetStrategy =
    formData.rebalancingStrategy === RebalanceStrategy.SHORT_STRATEGY ||
    formData.rebalancingStrategy === RebalanceStrategy.LEVERAGED_STRATEGY;

  const isPassiveIndexStrategy = formData.rebalancingStrategy === RebalanceStrategy.PASSIVE_INDEX;

  const isIndexProduct = generalDetailsData.productType === 'Index';

  const saveValues = (data: DefaultValues<BenchmarkDetailsProps>) => {
    saveInstrumentDraft({
      [BENCHMARK_STEP]: data as BenchmarkDetailsProps,
    });
  };

  const currencyOptions = useCurrencyOptions();

  return (
    <Form<BenchmarkDetailsProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentBenchmarkDetailsSchemaUrl}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="Benchmark Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <ETPStepSaveProgress />
        </FormGridItem>
        <FormGridItem>
          <Select
            name="rebalancingStrategy"
            mapOptions={(options) =>
              options
                .filter(
                  (option) =>
                    isIndexProduct ||
                    RebalanceStrategy.LEVERAGED_STRATEGY === option.value ||
                    RebalanceStrategy.SHORT_STRATEGY === option.value
                )
                .map((option) => ({
                  label:
                    RebalanceStrategyLabel[option.value as keyof typeof RebalanceStrategyLabel],
                  value: option.value,
                }))
            }
          />
        </FormGridItem>
        <FormGridItem>
          <Select name="rebalancingFrequency" capitalize={false} />
        </FormGridItem>
        {isPassiveIndexStrategy && (
          <FormGridItem>
            <Select
              name="rebalanceFeeCalculationMethod"
              mapOptions={(options) =>
                options.map((option) => ({
                  ...option,
                  tooltip: rebalanceFeeCalculationsTooltips(String(option.value)),
                }))
              }
            />
          </FormGridItem>
        )}
        {!isSingleAssetStrategy && (
          <>
            <FormGridItem>
              <Select name="indexProvider" allCaps />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingTicker" />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingIsin" />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingWkn" />
            </FormGridItem>

            <FormGridItem>
              <Select name="underlyingCurrency" options={currencyOptions} allCaps />
            </FormGridItem>

            <FormGridItem>
              <Input name="underlyingName" />
            </FormGridItem>

            <FormGridItem>
              <Input name="indexProviderSpecificId" />
            </FormGridItem>

            <FormGridItem>
              <Input name="dataAggregator" />
            </FormGridItem>
          </>
        )}
        <Footer goBack={goBack} />
      </FormGridContainer>
    </Form>
  );
}

export default BenchmarkDetailsStep;

const rebalanceFeeCalculationsTooltips = (
  option: string | undefined
): string | undefined | React.ReactNode => {
  switch (option) {
    case 'MULTIPLIER':
      return (
        <StyledToolTipRowsContainer>
          <StyledRow>
            <StyledTitleDiv>{`Multiplier Calculations:`}</StyledTitleDiv>
            <StyledContentDiv>
              {`Buy side →  Net trade = Gross Trade * (1-Rebalance Fee)`}
              <br />
              {`Sell side → Net trade = Gross Trade * (1+Rebalance Fee)`}
            </StyledContentDiv>
          </StyledRow>
        </StyledToolTipRowsContainer>
      );
    case 'DIVISOR':
      return (
        <StyledToolTipRowsContainer>
          <StyledRow>
            <StyledTitleDiv>{`Divisor Calculations:`}</StyledTitleDiv>
            <StyledContentDiv>
              {`Buy side →  Net trade = Gross Trade / (1+Rebalance Fee)`}
              <br />
              {`Sell side → Net trade = Gross Trade / (1-Rebalance Fee)`}
            </StyledContentDiv>
          </StyledRow>
        </StyledToolTipRowsContainer>
      );

    default:
      return undefined;
  }
};
