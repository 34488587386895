import { StepsListType } from 'pages/Instruments/components/Form/types';

export const GENERAL_DETAILS_STEP = 'General Details';
export const MARKET_STEP = 'Market';
export const BENCHMARK_STEP = 'Benchmark';
export const CONSTITUENT_STEP = 'Constituent';
export const EXCHANGE_STEP = 'Exchanges';
export const REVIEW_STEP = 'Review';
export const FACTSHEET_STEP = 'Factsheet';
export const DOCUMENTS_FINAL_TERMS_STEP = 'Final Terms';
export const DOCUMENTS_PCF_STEP = 'PCF';
export const SUMMARY_STEP_LABEL = 'Summary';

export const ORDER_PROFILE_STEP = 'Order Profile';
export const ORDER_PROFILE_STEP_CASH = 'Cash Order Profile';
export const ORDER_PROFILE_STEP_IN_KIND = 'In-Kind Order Profile';
export const ORDER_PROFILE_STEP_HYBRID = 'Hybrid Order Profile';
export const ORDER_PROFILE_STEP_CUSTOM_BASKET = 'Custom Basket Order Profile';

export const ORDER_PROFILE_STEP_CASH_NAV = 'Nav+';
export const ORDER_PROFILE_STEP_CASH_NAV_GUARANTEED = 'Nav Guaranteed';
export const ORDER_PROFILE_STEP_CASH_COIN_ENTITLEMENT = 'Coin Entitlement';

export type EtpDetailsStepType =
  | typeof GENERAL_DETAILS_STEP
  | typeof ORDER_PROFILE_STEP
  | typeof MARKET_STEP
  | typeof BENCHMARK_STEP
  | typeof CONSTITUENT_STEP
  | typeof EXCHANGE_STEP
  | typeof FACTSHEET_STEP
  | typeof DOCUMENTS_FINAL_TERMS_STEP
  | typeof DOCUMENTS_PCF_STEP
  | typeof REVIEW_STEP;

export const etpDetailsStepsList: StepsListType<EtpDetailsStepType>[] = [
  {
    id: GENERAL_DETAILS_STEP,
    label: GENERAL_DETAILS_STEP,
    nextStep: ORDER_PROFILE_STEP,
  },
  {
    id: ORDER_PROFILE_STEP,
    label: ORDER_PROFILE_STEP,
    nextStep: MARKET_STEP,
  },
  {
    id: MARKET_STEP,
    label: MARKET_STEP,
    nextStep: CONSTITUENT_STEP,
    previousStep: GENERAL_DETAILS_STEP,
  },
  {
    id: BENCHMARK_STEP,
    label: BENCHMARK_STEP,
    isDisabled: true,
  },
  {
    id: CONSTITUENT_STEP,
    label: CONSTITUENT_STEP,
    nextStep: EXCHANGE_STEP,
    previousStep: MARKET_STEP,
  },
  {
    id: EXCHANGE_STEP,
    label: EXCHANGE_STEP,
    nextStep: DOCUMENTS_FINAL_TERMS_STEP,
    previousStep: CONSTITUENT_STEP,
  },
  {
    id: DOCUMENTS_FINAL_TERMS_STEP,
    label: 'Documents',
    nextStep: FACTSHEET_STEP,
    previousStep: EXCHANGE_STEP,
    subSteps: [FACTSHEET_STEP, DOCUMENTS_PCF_STEP],
  },
  {
    id: FACTSHEET_STEP,
    label: FACTSHEET_STEP,
    nextStep: DOCUMENTS_PCF_STEP,
    previousStep: DOCUMENTS_FINAL_TERMS_STEP,
  },
  {
    id: DOCUMENTS_PCF_STEP,
    label: DOCUMENTS_PCF_STEP,
    nextStep: REVIEW_STEP,
    previousStep: FACTSHEET_STEP,
  },
  {
    id: REVIEW_STEP,
    label: REVIEW_STEP,
    previousStep: DOCUMENTS_PCF_STEP,
  },
];

export const indexEtpDetailsStepsList: StepsListType<EtpDetailsStepType>[] = [
  {
    id: GENERAL_DETAILS_STEP,
    label: GENERAL_DETAILS_STEP,
    nextStep: ORDER_PROFILE_STEP,
  },
  {
    id: ORDER_PROFILE_STEP,
    label: ORDER_PROFILE_STEP,
    nextStep: MARKET_STEP,
  },
  {
    id: MARKET_STEP,
    label: MARKET_STEP,
    nextStep: BENCHMARK_STEP,
    previousStep: GENERAL_DETAILS_STEP,
  },
  {
    id: BENCHMARK_STEP,
    label: BENCHMARK_STEP,
    nextStep: CONSTITUENT_STEP,
    previousStep: MARKET_STEP,
  },
  {
    id: CONSTITUENT_STEP,
    label: CONSTITUENT_STEP,
    nextStep: EXCHANGE_STEP,
    previousStep: BENCHMARK_STEP,
  },
  {
    id: EXCHANGE_STEP,
    label: EXCHANGE_STEP,
    nextStep: FACTSHEET_STEP,
    previousStep: CONSTITUENT_STEP,
  },
  {
    id: DOCUMENTS_FINAL_TERMS_STEP,
    label: 'Documents',
    nextStep: REVIEW_STEP,
    previousStep: EXCHANGE_STEP,
    subSteps: [FACTSHEET_STEP, DOCUMENTS_PCF_STEP],
  },
  {
    id: FACTSHEET_STEP,
    label: FACTSHEET_STEP,
    nextStep: DOCUMENTS_PCF_STEP,
    previousStep: DOCUMENTS_FINAL_TERMS_STEP,
  },
  {
    id: DOCUMENTS_PCF_STEP,
    label: DOCUMENTS_PCF_STEP,
    nextStep: REVIEW_STEP,
    previousStep: FACTSHEET_STEP,
  },
  {
    id: REVIEW_STEP,
    label: REVIEW_STEP,
    previousStep: DOCUMENTS_PCF_STEP,
  },
];
