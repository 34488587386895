import { isPast } from 'date-fns';

// constants, types
import { DEFER_ORDER_MODAL, ORDERS_MODAL } from 'shared/Modals/constants';
import { ActiveModalsType } from 'shared/Modals/types';
import { Deliverable, Order, OrderStatus } from 'utils/types/orders';
import { variantType } from 'components/Button/types';

// hooks
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions/useOrdersActions';
import { useUserPermissions } from 'store/user/selectors';

// utils
import getCloseTime from 'pages/Orders/Orders/utils/getCloseTime';
import { ProductType } from 'utils/types/product';

export type Action = {
  callback: (actualDeliverables?: Deliverable[]) => void;
  label: string;
  modal: ActiveModalsType;
  permission: boolean;
  status: OrderStatus;
  variant?: variantType;
};

export const useButtonsActions = (
  order: Order,
  refreshQueryKey?: string,
  productType: ProductType = 'ETP'
) => {
  const userPermissions = useUserPermissions();
  const { confirmAction, deferAction, submitAction, readyAction, settleAction, cancelAction } =
    useOrdersActions(order._id, refreshQueryKey, productType);
  const closeTime = getCloseTime(order);
  const isClosed = isPast(closeTime);

  const isApMarketOpen = !userPermissions?.isAuthorizedParticipant || !isClosed;

  const actions: Action[] = [
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.pending),
      callback: submitAction,
      label: 'Submit Order',
      status: OrderStatus.PENDING,
      variant: 'primary',
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.confirm),
      callback: confirmAction,
      label: 'Confirm Order',
      status: OrderStatus.CONFIRMED,
      variant: 'primary',
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.ready),
      callback: readyAction,
      label: 'Mark as Ready',
      status: OrderStatus.READY,
      variant: 'primary',
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.settle),
      callback: settleAction,
      label: 'Settle Order',
      status: OrderStatus.SETTLED,
      variant: 'primary',
    },
    {
      modal: DEFER_ORDER_MODAL,
      permission: Boolean(order?._actions?.defer) && !Boolean(order?.bookedAt),
      callback: deferAction,
      variant: 'secondary', // @TODO button should be greyish colored secondary
      label: 'Defer Settlement',
      status: OrderStatus.CANCELED,
    },
    {
      modal: ORDERS_MODAL,
      permission: Boolean(order?._actions?.cancel) && isApMarketOpen,
      callback: cancelAction,
      variant: 'danger',
      label: 'Cancel Order',
      status: OrderStatus.CANCELED,
    },
  ];

  return actions;
};
