import { IModalWithData } from 'shared/Modals/types';
import { ReactNode } from 'react';
import { EditRebalanceFeesModal } from './EditRebalanceFeesModal';

export const RebalanceFeesModal = ({ ...props }: IModalWithData) => {
  const RebalanceFeeModals: Record<string, ReactNode> = {
    EDIT: <EditRebalanceFeesModal {...props} />,
  };

  return <>{RebalanceFeeModals[props.data.type]}</>;
};
