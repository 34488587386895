export const DELETE_CONTACTS_FROM_CONTACT_LIST_MODAL = 'deleteContactsFromContactListModal';
export const ADD_CONTACT_TO_CONTACT_LIST_MODAL = 'addContactTocontactListModal';
export const CANCEL_ORDER_MODAL = 'cancelOrderModal';
export const USER_MODAL = 'userModal';
export const PARTNER_MODAL = 'partnerModal';
export const ETP_MODAL = 'etpModal';
export const TOKEN_MODAL = 'tokenModal';
export const WALLET_MODAL = 'walletModal';
export const CALENDAR_MODAL = 'calendarModal';
export const CALENDAR_EVENT_MODAL = 'calendarEventModal';
export const EXCHANGE_MODAL = 'exchangeModal';
export const CUSTODIAN_MODAL = 'custodianModal';
export const TOUCHED_FIELDS_MODAL = 'touchedFieldsModal';
export const PRODUCT_EXCHANGE_MODAL = 'productExchangeModal';
export const CONSTITUENT_MODAL = 'constituentModal';
export const YES_OR_NO_MODAL = 'yesOrNoModal';
export const REBALANCE_MODAL = 'rebalanceModal';
export const CONTACT_MODAL = 'contactModal';
export const CONTACT_LIST_MODAL = 'contactListModal';
export const REGISTER_MODAL = 'registerModal';
export const NAV_REVIEW_MODAL = 'navReveiwModal';
export const NAV_MODAL = 'NAVModal';
export const ORDERS_MODAL = 'ordersModal';
export const DEFER_ORDER_MODAL = 'deferOrderModal';
export const PCF_UPLOAD_MODAL = 'pcfUploadModal';
export const FEES_MODAL = 'feesModal';
export const API_KEY_MODAL = 'apiKeyModal';
export const REBALANCE_FEES_MODAL = 'rebalanceFeesModal';

export type BASE_MODAL_ACTIONS = Exclude<MODAL_ACTIONS, 'CUSTOM'>;

export enum MODAL_ACTIONS {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  CUSTOM = 'CUSTOM',
}

export const MODALS_TYPES = [
  CALENDAR_EVENT_MODAL,
  CALENDAR_MODAL,
  CANCEL_ORDER_MODAL,
  CONTACT_LIST_MODAL,
  REGISTER_MODAL,
  EXCHANGE_MODAL,
  PARTNER_MODAL,
  ETP_MODAL,
  TOKEN_MODAL,
  TOUCHED_FIELDS_MODAL,
  USER_MODAL,
  WALLET_MODAL,
  PRODUCT_EXCHANGE_MODAL,
  CUSTODIAN_MODAL,
  CONSTITUENT_MODAL,
  YES_OR_NO_MODAL,
  REBALANCE_MODAL,
  CONTACT_MODAL,
  NAV_REVIEW_MODAL,
  ORDERS_MODAL,
  DEFER_ORDER_MODAL,
  PCF_UPLOAD_MODAL,
  FEES_MODAL,
  API_KEY_MODAL,
  NAV_MODAL,
  REBALANCE_FEES_MODAL,
] as const;
