// TODO: move
import BigNumber from 'bignumber.js';
import { keyBy } from 'lodash';
import { NavOptionsTitles } from 'pages/Orders/Orders/types';
import { ORDER_IN_KIND } from 'utils/constants';
import { capitalizeFirstLetter, formatAsPercentage } from 'utils/formatting';
import { Order, ProductType, User, WalletInfo } from 'utils/types';
import { OrderScope } from 'utils/types/orders';
const ZERO_UNITS = 0;

function exportDeliveries(order: Order, type: 'expected' | 'actual') {
  if (type === 'expected') {
    return order.deliveries?.expected
      ?.filter((deliverable) => Boolean(deliverable.weight))
      .map((deliverable) => [
        // Asset
        deliverable?.ticker || '-',
        // Weight
        deliverable?.weight
          ? `${new BigNumber(deliverable?.weight)
              .multipliedBy(100)
              .toFixed(2, BigNumber.ROUND_HALF_UP)}%`
          : '-',
        // Quantity
        deliverable?.amount || '0',
        // Reference Price
        deliverable?.price || '-',
        // Market Value
        deliverable?.totalInCurrency || '-',
        // Expected Delivery
        order?.deliveryType === 'CASH'
          ? deliverable?.totalInDeliveryCurrency || '-'
          : deliverable?.amount || '-',
      ]);
  }

  if (type === 'actual') {
    const expectedDeliverableByTicker = keyBy(order?.deliveries?.expected, 'ticker');

    const { deliveryType } = order || {};
    if (deliveryType === 'CASH') {
      return order?.trades?.map((trade) => [
        // Asset
        trade.baseCurrency,
        // Weight
        expectedDeliverableByTicker[trade.baseCurrency].weight
          ? formatAsPercentage(expectedDeliverableByTicker[trade.baseCurrency].weight!, 2)
          : '-',
        // Quantity
        trade.quantity,
        // Closing Price
        trade.priceFromPriceProvider || '-',
        // Execution Price
        trade.price || '-',
        // Market Value
        trade?.total || '-',
        // Actual Delivery
        trade.totalInDeliveryCurrency || '-',
      ]);
    }

    if (deliveryType === 'IN_KIND') {
      return order?.deliveries?.actual
        ?.filter((deliverable) => Boolean(deliverable.amount))
        .map((deliverable) => [
          // Asset
          deliverable.ticker,
          // Weight
          expectedDeliverableByTicker[deliverable.ticker]?.weight
            ? expectedDeliverableByTicker[deliverable.ticker].weight
            : '-',
          // Quantity
          deliverable.amount,
          // Reference Price
          expectedDeliverableByTicker[deliverable.ticker]?.price || '-',
          // Market Value
          expectedDeliverableByTicker[deliverable.ticker]?.totalInCurrency || '-',
          // Actual Delivery
          expectedDeliverableByTicker[deliverable.ticker]?.amount || '-',
        ]);
    }
  }
}

function exportIndexBasketDeliveries(order: Order) {
  const deliverables = order?.deliveries?.underlyingExpected || order?.deliveries?.expected;

  return deliverables
    ?.filter((deliverable) => Boolean(deliverable.weight))
    .map((deliverable) => [
      // Asset
      deliverable?.ticker || '-',
      // Weight
      deliverable?.weight
        ? `${new BigNumber(deliverable?.weight)
            .multipliedBy(100)
            .toFixed(2, BigNumber.ROUND_HALF_UP)}%`
        : '-',
      // Quantity
      deliverable?.amount || '-',
      // Reference Price
      deliverable.price || '-',
      // Market Value
      deliverable.totalInCurrency || '-',
    ]);
}

function getOrderCsvData(order: Order, user: User, productType: ProductType) {
  const expectedDeliveries = exportDeliveries(order, 'expected');
  const actualDeliveries = exportDeliveries(order, 'actual');
  const issuerWallets = order?.issuerWallets?.map((wallet: WalletInfo) => [
    `Issuer ${wallet.ticker} Wallet`,
    wallet.address,
  ]);

  let csvData: (string | number | undefined)[][] = [];

  if (productType === 'ETP') {
    const apWallets = order?.authorizedParticipantWallets?.map((wallet: WalletInfo) => [
      `Authorized Participant ${wallet.ticker} Wallet`,
      wallet.address,
    ]);

    csvData = [
      // Deal Information
      ['Deal Information'],
      ['Order Number', order.externalId],
      ['State', order?.status],
      ['Deal Date', order.dealDate],
      ['Settlement Date', order.settlement?.date],
      ['Order', order?.type ? capitalizeFirstLetter(order?.type) : '-'],
      ['Delivery Type', order?.deliveryType ? order?.deliveryType?.replaceAll('_', '-') : '-'],
      ...(order?.deliveryType === 'CASH'
        ? [['Order Type', order?.pricingType ? NavOptionsTitles[order?.pricingType] : '-']]
        : []),
      ...(order?.deliveryType === 'CASH'
        ? [['Delivery Currency', order?.deliveryCurrency]]
        : [['Delivery Currency', '-']]),
      [
        'Authorised Participant',
        order?.createdBy.onBehalfOf
          ? order.createdBy.onBehalfOf?.companyName
          : order?.createdBy?.companyName,
      ],
      ['User Email', order?.createdBy.email ? order.createdBy.email : user?.contact.email],
      ['AP Code', order?.authorizedParticipantCode || '-'],
      ['Number of Securities', order?.numberOfSecurities || ZERO_UNITS],
      ['Number of Creation Units', order?.numberOfUnits || ZERO_UNITS],
      ['Settlement Type', order?.settlement?.type],
      ['Standard Settlement Horizon', order?.settlement?.horizon],
      [],
      // Expected Deliverables
      ['Expected Deliverables'],
      ['Asset', 'Weight', 'Quantity', 'Reference Price', 'Market Value', 'Expected Delivery'],
      ...(expectedDeliveries || []),
      [],
      // Actual Deliverables
      ['Actual Deliverables'],
      ...(order?.deliveryType === 'CASH'
        ? [
            [
              'Asset',
              'Weight',
              'Quantity',
              'Closing Price',
              'Execution Price',
              'Market Value',
              'Actual Delivery',
            ],
          ]
        : [['Asset', 'Weight', 'Quantity', 'Reference Price', 'Market Value', 'Actual Delivery']]),
      ...(actualDeliveries || []),
      [],
      ...(order?.deliveryType === 'CASH'
        ? [
            // Fee Breakdown
            ['Fee Breakdown'],
            ['Admin Fee', order?.tradesAdminFee || order?.tradesAdjustedAdminFee || '-'],
            ['Execution Fee (10bps)', order?.tradesExecutionFee || '-'],
            ['Total Fees', order?.tradesTotalFees || '-'],
            [],
            // Total Amount to Settle
            ['Total Amount to Settle'],
            ['Order Amount', order?.tradesTotalFromPriceProviderInDeliveryCurrency || '-'],
            [
              'Slippage',
              order?.tradesTotalSlippageFromTotalAndPriceProviderInDeliveryCurrency || '-',
            ],
            ['Trade Amount', order?.tradesTotalInDeliveryCurrency || '-'],
            ['Total Fees', order?.tradesTotalFeesInDeliveryCurrency || '-'],
            ['Total Amount to Settle', order?.tradesTotalWithTotalFeeInDeliveryCurrency || '-'],
            [],
          ]
        : []),
      [],
      // Standard Basket
      ...(order?.scope === OrderScope.CUSTOM_BASKET
        ? [
            ['Standard Basket'],
            ['Asset', 'Weight', 'Quantity', 'Reference Price', 'Market Value'],
            ...(exportIndexBasketDeliveries(order) || []),
          ]
        : []),
      [],
      // Product Information
      ['Product Information'],
      ['Product Name', order?.product?.name],
      ['Series Name', order?.product?.seriesName],
      ['Series Letter', order?.product?.seriesLetter],
      ['Product ISIN', order?.product?.isin],
      [],
      // Wallets Information
      ['Wallets Information'],
      ...(apWallets || []),
      ...(issuerWallets || []),
      [],
      // Settlement Information
      ['Settlement Information'],
      ['Authorized Participant BP ID (SIX SIS AG)', process.env.REACT_APP_SETTLEMENT_BPID],
      ['Delivery/Receive', order?.deliveryType === ORDER_IN_KIND ? 'RECEIVE' : 'DELIVERY'],
      ['Issuer BP ID (SIX SIS AG)', process.env.REACT_APP_SETTLEMENT_BPID],
      ['Delivery/Receive', order?.deliveryType === ORDER_IN_KIND ? 'DELIVERY' : 'RECEIVE'],
    ];
  } else if (productType === 'Token') {
    const amWallets = order?.authorizedParticipantWallets?.map((wallet: WalletInfo) => [
      `Authorized Merchant ${wallet.ticker} Wallet`,
      wallet.address,
    ]);

    csvData = [
      ['Order Number', order.externalId],
      ['Deal Date', order.dealDate],
      ['Settlement Date', order.settlement?.date],
      ['Mint/Burn', order.type],
      ['Delivery Type', order?.deliveryType],
      ['Authorised Merchant', order?.createdBy?.companyName],
      ['User Email', user?.contact.email],
      [],
      ...(expectedDeliveries || []),
      ...(actualDeliveries || []),
      [],
      ['Product Name', order?.product?.name],
      ['Source Chain', order?.product?.sourceChain],
      ['Destination Chain', order?.product?.destinationChain],
      ['Mint/Burn Contract ID', order?.product?.contractAddress],
      [],
      ...(amWallets || []),
      ...(issuerWallets || []),
      [],
    ];
  }

  return csvData;
}

function downloadCsv(order: Order, user: User, productType: ProductType) {
  const csvData = getOrderCsvData(order, user, productType);
  const csvContent =
    'data:text/csv;charset=utf-8,' + csvData.map((row) => row.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${order.externalId}.csv`);
  document.body.appendChild(link);

  link.click();
}

export default downloadCsv;
