import { Deliverable, Order, OrderFlow } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import ExpectedSeedDeliverablesTable from './components/ExpectedSeedDeliverablesTable';
import DeliverablesTable from './components/ExpectedDeliverablesTable';

export interface ExpectedDeliverablesProps {
  productType: ProductType;
  onChangeSeedDeliverable?: (deliverables: Deliverable) => void;
  order?: Order;
  seedDeliverables?: Deliverable[];
  orderFlow: OrderFlow;
}

const ExpectedDeliverables = ({
  productType,
  onChangeSeedDeliverable: onChangeSeedDeliverable,
  order,
  seedDeliverables,
  orderFlow,
}: ExpectedDeliverablesProps) => {
  const isSeedOrder = Boolean(order?.isSeed);

  return (
    <>
      {productType === 'ETP' && isSeedOrder && orderFlow === 'delegate' ? (
        <ExpectedSeedDeliverablesTable
          onChangeSeedDeliverable={onChangeSeedDeliverable}
          seedDeliverables={seedDeliverables}
        />
      ) : (
        <DeliverablesTable
          productType={productType}
          order={order}
          deliverables={order?.deliveries?.expected}
        />
      )}
    </>
  );
};

export default ExpectedDeliverables;
