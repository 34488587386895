import { Box, TableBody, Typography, Stack } from '@mui/material';
import { Button } from 'components/Button/Button';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import Table, { TableCell, TableRow } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import Input from 'components/Input';
import ProductIcon from 'components/ProductIcon';
import { Checkbox } from 'components/BooleanInputs';
import Notification from 'components/Notification';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
// Hooks and Utilities
import { useState } from 'react';
import { useMutation } from 'react-query';
import useAppDispatch from 'hooks/useAppDispatch';
import { useUrlParams } from 'hooks/useUrlParams';
import { createNotification } from 'store/notifications/actions';
// API and Constants
import { bulkUpdateRebalanceFees } from 'utils/api/rebalance';
import { RebalanceWithDetails, BulkUpdateRebalanceFeesDto } from 'utils/types/rebalance';
import { REBALANCE_TAB_STATUS } from 'utils/constants/rebalance';
import { DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { DateTime } from 'utils/datetime';
import { privateRoutesUrls } from 'router/constants';
import { useRebalanceQuery } from 'pages/Rebalance/hooks/useRebalance';

export const EditRebalanceFeesModal = ({ closeModal, data }: IModalWithData) => {
  const rebalances = data.data as RebalanceWithDetails[];

  // Default Params
  const sharedDefaults = {
    page: DEFAULT_PAGE,
    pageSize: 50,
  };

  const upcomingDefaults = {
    ...sharedDefaults,
    tab: REBALANCE_TAB_STATUS.UPCOMING,
    valuationDate: new DateTime().toDateString(),
  };

  const { urlParams } = useUrlParams(
    upcomingDefaults,
    privateRoutesUrls.dashboardRoutes.rebalance,
    true
  );

  const [products, setProducts] = useState(
    rebalances.map((rebalance) => ({
      checked: true,
      constituents: rebalance.constituents,
      icon: rebalance.product.fundIcon,
      productId: rebalance.product._id,
      showConstituents: false,
      ticker: rebalance.product.ticker,
    }))
  );
  const [buyFeeOverride, setBuyFeeOverride] = useState(0);
  const [sellFeeOverride, setSellFeeOverride] = useState(0);

  const dispatch = useAppDispatch();

  const handleCheckboxChange = (index: number) => {
    setProducts((prev) =>
      prev.map((product, ind) =>
        ind === index ? { ...product, checked: !product.checked } : product
      )
    );
  };

  const handleChevronClick = (index: number) => {
    setProducts((prev) =>
      prev.map((product, ind) =>
        ind === index ? { ...product, showConstituents: !product.showConstituents } : product
      )
    );
  };

  const { refetch } = useRebalanceQuery(urlParams);

  const rebalanceFeesMutation = useMutation({
    mutationFn: (dto: BulkUpdateRebalanceFeesDto) => bulkUpdateRebalanceFees(dto),
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: `Rebalance fees updated successfully for all selected products`,
          title: 'Update Successful',
          type: 'success',
        })
      );
      closeModal();
      refetch(); // Refetch rebalances to ensure updated values in modal tables
    },
    onError: (err: Error) => {
      dispatch(
        createNotification({
          message: `Failed to update rebalance fees (${err.message ?? ''})`,
          title: 'Update Failed',
          type: 'error',
        })
      );
    },
  });

  const handleSubmit = () => {
    const selectedProducts = products.filter((product) => product.checked);

    if (selectedProducts.length === 0) {
      dispatch(
        createNotification({
          message: 'No products selected to update.',
          title: 'No Changes',
          type: 'info',
        })
      );
      return;
    }

    const productIDs = selectedProducts.map((product) => product.productId);

    rebalanceFeesMutation.mutate({
      buyFeeBps: buyFeeOverride,
      sellFeeBps: sellFeeOverride,
      productIds: productIDs,
    });
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        noPadding
        header={<CardHeaderTitleWithLabel title="Override Fees" label={'Rebalance'} />}
        body={
          <Stack>
            <Table>
              <TableHeaderWithMultiSort
                columns={[
                  { label: '', propName: 'bulkFeeOverride', propType: 'string' },
                  { label: 'Buy fee', propName: 'buyFee', propType: 'string' },
                  { label: 'Sell fee', propName: 'sellFee', propType: 'string' },
                ]}
              />
              <TableBody>
                <TableRow>
                  {/* Bulk fee override */}
                  <TableCell>
                    Bulk Fee override
                    <Tooltip
                      title={
                        <TooltipContent description="These fee values will apply to all constituents of the selected products." />
                      }
                      placement="top"
                    ></Tooltip>
                  </TableCell>
                  {/* Buy fee */}
                  <TableCell>
                    <Input
                      size="small"
                      withTag
                      tagCopy="bps"
                      value={buyFeeOverride}
                      type="number"
                      onChange={(event) => setBuyFeeOverride(Number(event.currentTarget.value))}
                    ></Input>
                  </TableCell>
                  {/* Sell fee */}
                  <TableCell>
                    <Input
                      size="small"
                      withTag
                      tagCopy="bps"
                      value={sellFeeOverride}
                      type="number"
                      onChange={(event) => setSellFeeOverride(Number(event.currentTarget.value))}
                    ></Input>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHeaderWithMultiSort
                checkboxProps={{
                  label: 'Override product',
                  checked: true,
                  onChange: (checked: boolean) => {
                    setProducts((prev) => prev.map((product) => ({ ...product, checked })));
                  },
                }}
                columns={[
                  {
                    label: 'Constituents',
                    propName: 'constituents',
                    propType: 'string',
                    width: '300px',
                  },
                  { label: '', propName: 'chevron', propType: 'string', width: '10px' },
                ]}
              />
              <TableBody>
                {products?.map((product, index) => (
                  <>
                    <TableRow>
                      {/* Override product */}
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Checkbox
                            checked={product.checked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          <ProductIcon iconUrl={product.icon} ticker={product.ticker} />
                          <Typography marginLeft={1}>{product.ticker}</Typography>
                        </Box>
                      </TableCell>
                      {/* Constituents */}
                      <TableCell>{product.constituents.length}</TableCell>
                      {/* Chevron */}
                      <TableCell
                        onClick={() => handleChevronClick(index)}
                        sx={{ textAlign: 'right' }}
                      >
                        {product.showConstituents ? <ChevronUp /> : <ChevronDown />}
                      </TableCell>
                    </TableRow>
                    {/* Constituents table dropdown  */}
                    {product.showConstituents && (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <Box padding={1} sx={{ background: '#F0F4F7' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                              <TableHeaderWithMultiSort
                                columns={[
                                  {
                                    label: 'Ticker',
                                    propName: 'ticker',
                                    propType: 'string',
                                    width: '10px',
                                  },
                                  {
                                    label: 'Buy',
                                    propName: 'buyFee',
                                    propType: 'string',
                                    width: '10px',
                                  },
                                  {
                                    label: 'Sell',
                                    propName: 'sellFee',
                                    propType: 'string',
                                    width: '10px',
                                  },
                                ]}
                              />
                              <TableBody>
                                {product?.constituents?.map((constituent) => {
                                  return (
                                    <TableRow key={constituent.ticker}>
                                      <TableCell>{constituent.ticker}</TableCell>
                                      <TableCell>{`${constituent.fees.buyFeesBps}bps`}</TableCell>
                                      <TableCell>{`${constituent.fees.sellFeesBps}bps`}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
            <Box padding={2} paddingTop={0}>
              <Notification
                variant="warning"
                title="Fee Override is Permanent"
                message={
                  'Once you override the fees using this modal, the changes cannot be undone automatically. If you wish to revert them, you will need to manually adjust the fees again.'
                }
              />
            </Box>
          </Stack>
        }
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Box display={'flex'} gap={2} justifyContent={'flex-end'}>
                <Button
                  data-qa-id="doneButton"
                  variant="tertiary"
                  fullWidth
                  onClick={closeModal}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  data-qa-id="doneButton"
                  variant="primary"
                  fullWidth
                  onClick={handleSubmit}
                  type="button"
                >
                  Confirm Fees
                </Button>
              </Box>
            </MuiStyledModalFooterButtons>
          </Box>
        }
      ></Card>
    </CustomModal>
  );
};
