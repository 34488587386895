import { loadAssets } from 'utils/api/assets';
import useAppDispatch from './useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useQuery } from 'react-query';
import { getExistingAssets } from 'utils/constants/reactQueries';
import { useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { AssetClass } from 'utils/types';

export const useAssetsQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery([getExistingAssets], async () => {
    try {
      const assets = loadAssets();
      return assets;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};

export const useAssetsOptions = (assetType?: AssetClass) => {
  const { data: assets, isLoading } = useAssetsQuery();
  const [filterText, setFilterText] = useState<string | undefined>();
  const assetsOptions = useMemo(
    () =>
      assets
        ?.filter((asset) => {
          if (assetType && asset.type !== assetType) return false;
          if (isNil(filterText)) return true;
          const combined = `${asset.name} ${String(asset.ticker)}`.toLowerCase();
          return combined.includes(filterText.toLowerCase());
        })
        .map((asset) => ({
          label: asset.ticker,
          value: asset.ticker,
        })),
    [assetType, assets, filterText]
  );

  return { assetsOptions: assetsOptions ?? [], assets, isLoadingAssets: isLoading, setFilterText };
};
