import { DefaultValues } from 'react-hook-form';

// types, constants
import { FACTSHEET_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { FactsheetProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';

// components
import Form from 'components/Form/Form';
import Footer from 'pages/Instruments/components/Footer';
import Select from 'components/Select';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import { useInstrumentStepFormData } from 'store/instruments/selectors';
import { Toggle } from 'components/BooleanInputs';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import useFormSchemaUrl from 'pages/Instruments/components/Form/helpers/useFormSchemaUrl';

interface FactsheetStepProps {
  goBack: () => void;
  onSubmit: () => void;
}

function FactsheetStep({ goBack, onSubmit }: FactsheetStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(FACTSHEET_STEP) as FactsheetProps;
  const instrumentFactsheetSchemaUrl = useFormSchemaUrl(FACTSHEET_STEP);

  const saveValues = (data: DefaultValues<FactsheetProps>) => {
    saveInstrumentDraft({
      [FACTSHEET_STEP]: data as FactsheetProps,
    });
  };

  return (
    <Form<FactsheetProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentFactsheetSchemaUrl}
    >
      <FormGridContainer>
        <ETPStepSaveProgress />
        <FormGridItem>
          <Select
            data-qa-id="replicationMethod"
            data-qa-options-id="replicationMethod"
            name="replicationMethod"
          />
        </FormGridItem>
        <FormGridItem>
          <Select data-qa-id="domicile" data-qa-options-id="domicile" name="domicile" allCaps />
        </FormGridItem>
        <FormGridItem>
          <Select
            data-qa-id="legalStructure"
            data-qa-options-id="legalStructure"
            name="legalStructure"
          />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            data-qa-id="registeredCountries"
            data-qa-options-id="registeredCountries"
            name="registeredCountries"
            size="large"
          />
        </FormGridItem>
        <FormGridItem>
          <Toggle name="lendingEligibility" sizeVariant="large" switchLabelWithTitle />
        </FormGridItem>
        <FormGridItem>
          <Toggle name="shariahCompliant" sizeVariant="large" switchLabelWithTitle />
        </FormGridItem>
        <FormGridItem>
          <Toggle name="ucitsEligibility" sizeVariant="large" switchLabelWithTitle />
        </FormGridItem>
      </FormGridContainer>
      <Footer goBack={goBack} />
    </Form>
  );
}

export default FactsheetStep;
