/* eslint-disable max-lines-per-function */
import { DefaultValues } from 'react-hook-form';

// types, constants
import { OrderProfileProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import {
  ORDER_PROFILE_STEP_CASH,
  ORDER_PROFILE_STEP_IN_KIND,
  ORDER_PROFILE_STEP_HYBRID,
  ORDER_PROFILE_STEP_CUSTOM_BASKET,
  EtpDetailsStepType,
  ORDER_PROFILE_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';

// components
import Form from 'components/Form/Form';
import Footer from 'pages/Instruments/components/Footer';

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import Input from 'components/Input';
import { OrderProfileCashStep } from './Cash/Cash';
import ProgressIndicator, { ProgressIndicatorProps } from 'components/ProgressIndicator';
import { Column, Row } from 'components/Grid';
import { useState } from 'react';
import { OrderProfileInKindStep } from './InKind/InKind';
import Select from 'components/Select';

interface OrderProfileStepProps {
  goBack: () => void;
  onSubmit: () => void;
  goToStep: (step: EtpDetailsStepType) => void;
}

const tab = [
  { id: ORDER_PROFILE_STEP_CASH, label: ORDER_PROFILE_STEP_CASH, component: OrderProfileCashStep },
  {
    id: ORDER_PROFILE_STEP_IN_KIND,
    label: ORDER_PROFILE_STEP_IN_KIND,
    component: OrderProfileInKindStep,
  },
  {
    id: ORDER_PROFILE_STEP_HYBRID,
    label: ORDER_PROFILE_STEP_HYBRID,
    component: OrderProfileCashStep,
  },
  {
    id: ORDER_PROFILE_STEP_CUSTOM_BASKET,
    label: ORDER_PROFILE_STEP_CUSTOM_BASKET,
    component: OrderProfileCashStep,
  },
] as const;

type TabType = (typeof tab)[number]['id'];

function OrderProfileStep({ goBack, onSubmit, goToStep }: OrderProfileStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(ORDER_PROFILE_STEP) as OrderProfileProps;
  const saveValues = async (data: DefaultValues<OrderProfileProps>) => {
    saveInstrumentDraft({
      [ORDER_PROFILE_STEP]: data as OrderProfileProps,
    });
  };

  const [orderProfileActiveStep, setOrderProfileActiveStep] = useState(ORDER_PROFILE_STEP_CASH);

  const progress: ProgressIndicatorProps<TabType>['steps'] = tab.map(({ id, label }) => ({
    id,
    label,
    isActive: id === (orderProfileActiveStep as TabType),
    isDisabled: label !== ORDER_PROFILE_STEP_CASH && label !== ORDER_PROFILE_STEP_IN_KIND,
  }));

  const Tab = tab.find(({ id }) => id === orderProfileActiveStep)!.component;

  return (
    <Form<OrderProfileProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={'products/schema/data=order-profile'}
    >
      <FormGridContainer>
        <FormGridItem>
          <Input name="creationUnitSize" />
        </FormGridItem>
        <FormGridItem>
          <Select
            name="defaultSettlementHorizon"
            data-qa-id="defaultSettlementHorizon"
            data-qa-options-id="defaultSettlementHorizon"
          />
        </FormGridItem>
      </FormGridContainer>
      <Row>
        <Column>
          <ProgressIndicator
            fitContent
            noIcon
            onClickStep={(step) => setOrderProfileActiveStep(step)}
            steps={progress}
          />
        </Column>
      </Row>
      <Tab key={orderProfileActiveStep} onSubmit={onSubmit} />
      <Footer />
    </Form>
  );
}

export default OrderProfileStep;
