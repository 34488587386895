import Form from 'components/Form/Form';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import { OrderProfileProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { useInstrumentStepFormData } from 'store/instruments/selectors';
import { ORDER_PROFILE_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { DefaultValues } from 'react-hook-form';
import { renderDeliveryCutoff } from 'pages/Instruments/components/Form/EtpDetails/components/GeneralDetailsStep/utils/renderCutoff';
import { Checkbox } from 'components/BooleanInputs';
import FormGridItem from 'components/Form/components/FormGridItem';

interface OrderProfileStepProps {
  onSubmit: () => void;
}
export const NavGuaranteedStep = ({ onSubmit }: OrderProfileStepProps) => {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(ORDER_PROFILE_STEP) as OrderProfileProps;
  const saveValues = async (data: DefaultValues<OrderProfileProps>) => {
    saveInstrumentDraft({
      [ORDER_PROFILE_STEP]: data as OrderProfileProps,
    });
  };

  return (
    <Form<OrderProfileProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={'products/schema/data=order-profile-cash-nav-guaranteed'}
    >
      <FormGridContainer>
        <FormGridItem>
          <Checkbox name="allowNavGuaranteed" />
        </FormGridItem>
      </FormGridContainer>
      <FormGridContainer>
        {renderDeliveryCutoff('CASH', true, {
          timeName: 'cashNavGuaranteedTPlusNCutOffTime',
        })}
        {renderDeliveryCutoff('CASH', true, {
          timeName: 'cashNavGuaranteedTPlusZeroCutOffTime',
        })}
      </FormGridContainer>{' '}
    </Form>
  );
};
