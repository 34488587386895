import { PortfolioComposition } from 'utils/types/pcfs';
import Table, { TableCell, TableRow } from 'components/Table';
import { ProductConstituentAsset } from 'utils/types';
import { keyBy } from 'lodash';
import { Box, TableBody, TableHead, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

interface PCFCompositionProps {
  pcf?: PortfolioComposition & { constituentAssets?: ProductConstituentAsset[] };
}

function PCFComposition({ pcf }: PCFCompositionProps) {
  const productConstituents = keyBy(pcf?.constituentAssets, 'ticker');

  return pcf?.official?.constituents?.length ? (
    <Card
      header={
        <Box padding={'16px'}>
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            Portfolio Composition
          </Typography>
        </Box>
      }
      body={
        <Table noPadding>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Ticker</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Weight</TableCell>
              <TableCell align="right">Deliverable per Creation Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pcf?.official?.constituents?.map((constituent) => (
              <TableRow key={constituent.ticker}>
                <TableCell>
                  {productConstituents[constituent.ticker]?.isLeveraged
                    ? `${constituent.name} Exposure`
                    : constituent.name}
                </TableCell>
                <TableCell>{constituent.ticker}</TableCell>
                <TableCell align="right">{constituent.price}</TableCell>
                <TableCell align="right">{constituent.weight}</TableCell>
                <TableCell align="right">{constituent.amountPerCreationUnit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      }
    />
  ) : null;
}

export default PCFComposition;
