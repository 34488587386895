import ProductIcon from 'components/ProductIcon';
import Select from 'components/Select';
import { ETPType, ProductBaseActions, ProductStatus, TokenType } from 'utils/types/product';
import { GroupedOptionsType, OnChangeType } from 'components/Select/Select.types';
import { Instrument, InstrumentList } from 'utils/types';
import { StyledProductSelectorContainer } from 'components/ProductSelector/ProductSelector.styles';
import { groupBy, isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useInstrumentsQuery } from 'hooks/useInstrumentsQuery';
import MultiSelect from 'components/MultiSelect/MultiSelect';

interface ProductSelectorProps {
  className?: string;
  clearable?: boolean;
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  fullWidth?: boolean;
  label?: string;
  name?: string;
  onChange?: OnChangeType<string>;
  permissionFilter?: ProductBaseActions;
  placeholder?: string;
  resetOnChange?: string[];
  status?: ProductStatus[];
  value?: string | string[];
  disabled?: Boolean;
  productType?: ETPType;
  size?: 'small' | 'medium' | 'large';
  isMulti?: boolean;
  companyId?: string;
}

function mapProductsToOptions(
  productsList: InstrumentList | Instrument[],
  permissionFilter?: ProductBaseActions,
  productType?: ETPType | TokenType
) {
  let products;

  if (Array.isArray(products)) {
    products = productsList as Instrument[];
  } else {
    products = Object.values(productsList || {}) as Instrument[];
  }

  const mappedProducts = products
    .filter(
      (product) =>
        !permissionFilter || Boolean(product._actions && product._actions[permissionFilter])
    )
    .filter((product) => (productType ? product.type === productType : true))
    .map((product) => {
      return {
        label: product.ticker,
        value: product._id,
        type: product.type,
        icon: <ProductIcon iconUrl={product.fundIcon} ticker={product.ticker} size="16px" />,
      };
    });

  return groupBy(mappedProducts, 'type');
}

// @TODO TOKENS - convert component to support tokens
function ProductSelector({
  clearable,
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  fullWidth,
  label,
  name,
  onChange,
  permissionFilter,
  value,
  placeholder,
  className,
  status = [ProductStatus.ACTIVE],
  disabled,
  productType,
  size,
  isMulti,
  companyId,
}: ProductSelectorProps) {
  const { data: { data: products } = {} } = useInstrumentsQuery(companyId, status);

  const [options, setOptions] = useState<GroupedOptionsType<string>>();

  const handleFilter = (filter: string | undefined) => {
    if (filter) {
      const normalizedFilter = filter.toLowerCase();

      const filteredProducts = Object.values<Instrument>(products || {}).filter(
        (option: Instrument) =>
          option.ticker.toLowerCase().includes(normalizedFilter) ||
          option.name.toLowerCase().includes(normalizedFilter)
      );
      setOptions(mapProductsToOptions(filteredProducts, permissionFilter, productType));
    } else {
      return setOptions(mapProductsToOptions(products || {}, permissionFilter, productType));
    }
  };

  useEffect(() => {
    setOptions(mapProductsToOptions(products || [], permissionFilter, productType));
  }, [products, permissionFilter]);

  if (isMulti) {
    return (
      <StyledProductSelectorContainer fullwidth={fullWidth} className={className}>
        <MultiSelect
          data-qa-id={dataQaId}
          data-qa-options-id={dataQaOptionsId}
          label={label}
          name={name}
          onChange={onChange}
          onFilter={handleFilter}
          options={options}
          placeholder={placeholder ?? 'Select products'}
          value={isArray(value) ? value : []}
          disabled={Boolean(disabled)}
          size={size}
        />
      </StyledProductSelectorContainer>
    );
  }

  return (
    <StyledProductSelectorContainer fullwidth={fullWidth} className={className}>
      <Select
        clearable={clearable}
        data-qa-id={dataQaId}
        data-qa-options-id={dataQaOptionsId}
        filterable
        label={label}
        name={name}
        onChange={onChange}
        onFilter={handleFilter}
        options={options}
        placeholder={placeholder ?? 'Select product'}
        value={isArray(value) ? undefined : value}
        disabled={Boolean(disabled)}
        size={size}
      />
    </StyledProductSelectorContainer>
  );
}

export default ProductSelector;
