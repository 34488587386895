import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { errorHandler } from 'utils/apiUtils';
import { axiosInstance } from 'utils/axiosOnyx';
import { PaginationWithDataResponse } from 'utils/types';
import {
  RebalanceConstituent,
  RebalanceWithDetails,
  BulkUpdateRebalanceFeesDto,
} from 'utils/types/rebalance';

export async function getRebalances(queryParams: ParamsType, projection?: boolean) {
  try {
    const { data: assets } = await axiosInstance.get<
      PaginationWithDataResponse<RebalanceWithDetails[]>
    >(`${projection ? '/rebalances/projections' : '/rebalances'}?${getQueryString(queryParams)}`);
    return assets;
  } catch (err) {
    return errorHandler(err, 'Error while loading Rebalances');
  }
}

export async function getRebalance(productId?: string, date?: string) {
  try {
    const { data: rebalance } = await axiosInstance.get<RebalanceWithDetails>(
      `/rebalances/productId=${productId}/date=${date}`
    );
    return rebalance;
  } catch (err) {
    return errorHandler(err, 'Error while loading Rebalance');
  }
}

export async function calculateRebalanceDetails(
  id: string,
  rebalanceConstituents: RebalanceConstituent[]
) {
  try {
    const { data: rebalance } = await axiosInstance.post<RebalanceWithDetails>(
      `/rebalances/id=${id}/calculate`,
      {
        constituents: rebalanceConstituents,
      }
    );
    return rebalance;
  } catch (err) {
    return errorHandler(err, 'Error while calculating Rebalance');
  }
}

export async function approveRebalanceDetails(
  id: string,
  rebalanceConstituents?: RebalanceConstituent[]
) {
  try {
    const { data: rebalance } = await axiosInstance.post<RebalanceWithDetails>(
      `/rebalances/id=${id}/approve`,
      rebalanceConstituents
        ? {
            constituents: rebalanceConstituents,
          }
        : {}
    );
    return rebalance;
  } catch (err) {
    return errorHandler(err, 'Error while approving Rebalance');
  }
}

export async function bulkUpdateRebalanceFees(dto: BulkUpdateRebalanceFeesDto) {
  try {
    const { data: rebalance } = await axiosInstance.put<RebalanceWithDetails>(
      `/rebalances/fees`,
      dto
    );
    return rebalance;
  } catch (err) {
    return errorHandler(err, 'Error while updating Rebalance fees');
  }
}
