import { SecuritiesRegister } from 'pages/Orders/Register/types';
import Table from 'components/Table';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { formatAddress } from 'utils/formatting';
import Box from '@mui/material/Box';
import { Alert, Typography } from '@mui/material';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { CountryCode } from 'utils/constants/country';

export interface RegisterProductInformationProps {
  register?: SecuritiesRegister;
}

function formatDateToLongString(date: Date) {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  });
}

export const ProductInformation = ({ register }: RegisterProductInformationProps) => {
  const generalTerms = register?.product?.legalDocuments?.generalTermsDate;
  const finalTerms = register?.product?.legalDocuments?.finalTermsGeneratedAt;
  const generalTermsDateString = generalTerms
    ? formatDateToLongString(new Date(generalTerms))
    : '-';
  const finalTermsDateString = finalTerms ? formatDateToLongString(new Date(finalTerms)) : '-';

  const isInformationVisible = register?.product.jurisdiction === CountryCode.CH;
  const information = `According to the General Terms and Conditions of the Issuer’s Exchange Traded Products Programme dated as of ${generalTermsDateString}, as completed by the Final Terms dated ${finalTermsDateString}.`;
  return (
    <Box component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Product Information</Typography>
      </MuiStyledSectionHeader>
      <Table>
        <TableBodyWithStates hasContent={Boolean(register)}>
          <TableRow>
            <TableCell>Issuer</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.issuer.name), register?.issuer.name)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell align="right">
              {formatAddress([
                register?.issuer.address.addressLineOne,
                register?.issuer.address.addressLineTwo,
                register?.issuer.address.city?.name,
                register?.issuer.address.country?.name,
                register?.issuer.address.postalCode,
              ])}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Product Name</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.product.ticker), register?.product.ticker)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Product Ticker</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.product.name), register?.product.name)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ISIN</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.product.isin), register?.product.isin)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.product.currency), register?.product.currency)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Maturity</TableCell>
            <TableCell align="right">
              {renderOrderValue(Boolean(register?.product.maturity), register?.product.maturity)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total Securities Outstanding</TableCell>
            <TableCell align="right">
              {renderOrderValue(
                Boolean(register?.product.totalUnitsOutstanding),
                register?.product.totalUnitsOutstanding
              )}
            </TableCell>
          </TableRow>
        </TableBodyWithStates>
      </Table>
      {isInformationVisible && <Alert severity="warning">{information}</Alert>}
    </Box>
  );
};

export default ProductInformation;
