import { fromZonedTime as zonedTimeToDate } from 'date-fns-tz';

// types
import { Order } from 'utils/types/orders';
import { ORDER_IN_KIND } from 'utils/constants';

function getCloseTime(order: Order) {
  const cutoffs = order?.product?.cutoffs;
  const timezone = order?.product?.timezone;
  const deliveryType = order?.deliveryType;
  const pricingType = order?.pricingType;
  const isTPlusZero = order?.settlement?.horizon === 'T+0';

  const cutOffTimes = {
    CASH: {
      NAV_PLUS: isTPlusZero
        ? cutoffs?.cashNavPlusTPlusZeroCutOffTime
        : cutoffs?.cashNavPlusTPlusNCutOffTime,
      NAV_GUARANTEED: isTPlusZero
        ? cutoffs?.cashNavGuaranteedTPlusZeroCutOffTime
        : cutoffs?.cashNavGuaranteedTPlusNCutOffTime,
      COIN_ENTITLEMENT: isTPlusZero
        ? cutoffs?.cashCoinEntitlementTPlusZeroCutOffTime
        : cutoffs?.cashCoinEntitlementTPlusNCutOffTime,
    },
    IN_KIND: isTPlusZero ? cutoffs?.inKindTPlusZeroCutOffTime : cutoffs?.inKindTPlusNCutOffTime,
  };

  // TODO: select correct cut off time
  const cutOff =
    deliveryType === ORDER_IN_KIND
      ? cutOffTimes['IN_KIND']
      : cutOffTimes['CASH'][pricingType ?? 'NAV_PLUS'];

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat([], options);

  return cutOff && timezone
    ? zonedTimeToDate(new Date(`${formatter.format(new Date())} ${cutOff}`), timezone)
    : new Date();
}

export default getCloseTime;
