import { isEmpty, isNumber } from 'lodash';

// types
import {
  TokenFormDataProps,
  TokenFormDataStepType,
  TokenFormDataType,
} from 'pages/Tokens/Tokens.types';

import {
  CONSTITUENT_STEP,
  GENERAL_DETAILS_STEP,
} from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import {
  PARTNERS_STEP,
  CUSTODIAN_INFO_STEP,
  WALLETS_INFO_STEP,
} from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.steps';
// import { MarketDetailsProps } from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.types';
import { WalletsList } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import { StepsType } from 'pages/Tokens/components/Form/types';

// utils
import { getStep } from 'pages/Tokens/components/Form/utils';

// eslint-disable-next-line
function hasValue(value: any) {
  return isNumber(value) || !isEmpty(value) || typeof value === 'boolean';
}

const stepEmptinessChecker: {
  [key: string]: (values: TokenFormDataType | undefined) => boolean;
} = {
  [WALLETS_INFO_STEP]: (values: TokenFormDataType | undefined) => {
    const walletsList = values as WalletsList;
    return isEmpty(walletsList?.custodianWallets) && isEmpty(walletsList?.unifiedWallets);
  },
};

function isStepValuesEmpty<T extends StepsType>(
  stepLabel: T,
  values: TokenFormDataType | undefined
): boolean {
  const emptinessChecker = stepEmptinessChecker[stepLabel] || isEmpty;

  return emptinessChecker(values);
}

function getStepStatus<
  T extends StepsType,
  DataType extends TokenFormDataType,
  Property extends keyof DataType
>(
  activeStep: T,
  stepLabel: TokenFormDataStepType,
  completedSteps: T[],
  values: DataType | undefined,
  mandatoryProps: Property[] = [],
  isDisabled: boolean = false,
  isDirty: boolean = false
) {
  const step = getStep(stepLabel);
  const isActive = stepLabel === activeStep;
  const isInvalid =
    !isStepValuesEmpty(stepLabel, values) &&
    !isEmpty(
      mandatoryProps
        .map((prop) => values && values[prop])
        .map(hasValue)
        .filter((isValidValue) => !isValidValue)
    );

  const isComplete =
    !isStepValuesEmpty(stepLabel, values) &&
    (completedSteps.includes(step.label as T) || !isInvalid);

  return { isActive, isComplete, isDisabled, isInvalid, isDirty };
}

function getStepsStatuses<T extends StepsType>(
  token: TokenFormDataProps,
  activeStep: T,
  completedSteps: T[]
) {
  return {
    [PARTNERS_STEP]: getStepStatus(
      activeStep,
      PARTNERS_STEP,
      completedSteps,
      token[PARTNERS_STEP],
      ['authorizedParticipants', 'custodians', 'issuer'],
      false
    ),
    [CONSTITUENT_STEP]: getStepStatus(
      activeStep,
      CONSTITUENT_STEP,
      completedSteps,
      token[CONSTITUENT_STEP],
      [],
      false
    ),
    [GENERAL_DETAILS_STEP]: getStepStatus(
      activeStep,
      GENERAL_DETAILS_STEP,
      completedSteps,
      token[GENERAL_DETAILS_STEP],
      [
        'name',
        'ticker',
        'sourceChain',
        'tokenStandard',
        'destinationChain',
        'contractAddress',
        'mintFees',
        'burnFees',
      ],
      false
    ),
    [CUSTODIAN_INFO_STEP]: getStepStatus(
      activeStep,
      CUSTODIAN_INFO_STEP,
      completedSteps,
      token[CUSTODIAN_INFO_STEP],
      [],
      false
    ),
    [WALLETS_INFO_STEP]: getStepStatus(
      activeStep,
      WALLETS_INFO_STEP,
      completedSteps,
      token[WALLETS_INFO_STEP],
      ['custodianWallets'],
      false
    ),
  };
}

export default getStepsStatuses;
